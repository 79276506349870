import React, { useState, memo } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

const allFeatures = [
  "Livekasino",
  "Megaways-pelit",
  "Progressiiviset jackpot-pelit",
  "Vedonlyönti",
  "Bingo",
  "Raaputusarvat",
];

const StarIcon = memo(() => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: "10px" }}
    >
      <g clipPath="url(#clip0_1_57)">
        <path
          d="M5.44756 14.3795C4.90072 14.6532 4.22826 14.4443 3.94746 13.9184C3.82922 13.7023 3.79227 13.4574 3.83661 13.2269L4.42778 9.88443L1.90791 7.52885C1.46453 7.11104 1.44236 6.4267 1.86357 5.99449C2.03353 5.8144 2.26261 5.69914 2.50647 5.66312L5.9944 5.17328L7.5684 2.10454L7.56766 2.10382C7.83369 1.56355 8.49876 1.34745 9.05298 1.61398C9.26728 1.71483 9.44464 1.88772 9.54809 2.09662L11.1228 5.15959L14.6108 5.64223V5.64151C15.2167 5.72075 15.6379 6.26822 15.5492 6.85892C15.5123 7.09664 15.3941 7.31995 15.2093 7.48563L12.6894 9.834L13.2806 13.175C13.3841 13.7585 12.985 14.3204 12.3791 14.4213C12.1352 14.4573 11.884 14.4213 11.6697 14.3132L8.51428 12.7212L5.44756 14.3795Z"
          fill="#F7D048"
          stroke="#F7D048"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_57">
          <rect width="16" height="16" fill="white" transform="translate(0.561035)" />
        </clipPath>
      </defs>
    </svg>
  );
});

const ArrowIcon = memo(({ className }) => {
  return (
    <svg className={className} width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L5 5L9 1" stroke="#2B54E2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
});

const DoneIcon = memo(() => {
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 1.5L6 12.5L1 7.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
});

const CrossIcon = memo(() => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.53033 0.46967C1.23744 0.176777 0.762563 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L1.53033 0.46967ZM8.46967 9.53033C8.76256 9.82322 9.23744 9.82322 9.53033 9.53033C9.82322 9.23744 9.82322 8.76256 9.53033 8.46967L8.46967 9.53033ZM9.53033 1.53033C9.82322 1.23744 9.82322 0.762563 9.53033 0.46967C9.23744 0.176777 8.76256 0.176777 8.46967 0.46967L9.53033 1.53033ZM0.46967 8.46967C0.176777 8.76256 0.176777 9.23744 0.46967 9.53033C0.762563 9.82322 1.23744 9.82322 1.53033 9.53033L0.46967 8.46967ZM0.46967 1.53033L8.46967 9.53033L9.53033 8.46967L1.53033 0.46967L0.46967 1.53033ZM8.46967 0.46967L0.46967 8.46967L1.53033 9.53033L9.53033 1.53033L8.46967 0.46967Z"
        fill="black"
      />
    </svg>
  );
});

const CasinoCard = ({
  casinoName,
  logo,
  description,
  link,
  rating,
  bonukset,
  features,
  ilmaiskierrokset,
  lisenssi,
  pelienMr,
}) => {
  const image = getImage(logo);

  function roundHalf(num) {
    return Math.round(num * 2) / 2;
  }
  const casinoRounded = roundHalf(rating);
  const [cardExpanded, setCardExpanded] = useState(false);
  const [ref, inView] = useInView({
    rootMargin: "-100px",
    triggerOnce: true,
  });

  return (
    <Casino ref={ref}>
      <div className="casino-card card casino-card--light">
        <CasinoTop className={`casinotop ${cardExpanded ? "expanded-card-casino" : ""}`}>
          <div className={`casinotop-content ${cardExpanded ? "expanded-casinotop-content" : ""}`}>
            <ContentWrapper>
              <Logo>
                <a href={link} target="_blank" rel="noreferrer" className="casino-card__link" aria-label={casinoName}>
                  {inView && <GatsbyImage image={image} alt={casinoName} imgStyle={{ zIndex: "1" }} />}
                </a>
                <div className="name">
                  <a href={link} target="_blank" rel="noreferrer" className="casino-card__link" aria-label={casinoName}>
                    <h3 className="title">{casinoName}</h3>
                  </a>
                </div>
              </Logo>
              <BoxFirst>
                <div className="contentInfo">
                  <div className="contentInfoItems">
                    <OfferItem>
                      <span>Bonukset:</span>
                      <Offer>{bonukset}</Offer>
                    </OfferItem>
                    <OfferItem>
                      <span>Ilmaiskierrokset:</span>
                      <Offer>{ilmaiskierrokset}</Offer>
                    </OfferItem>
                  </div>
                  {inView && <CasinoRate>{Array(casinoRounded).fill(<StarIcon />)}</CasinoRate>}
                </div>
              </BoxFirst>
              <BoxSecond>
                <div className="contentText">
                  <BodyText>{description}</BodyText>
                </div>
              </BoxSecond>
            </ContentWrapper>
            <div className="contentButtonWrapper">
              <ContentButton>
                <div className="contentBtn only">
                  <CasinoButton
                    href={link}
                    target="_blank"
                    rel="noopener sponsored noreferrer"
                    data-list="DZADARATBEBOBABRCACLCOCZECEGFIDEHUISINIEJPKZLILVLTLUMTMXMCMANLNZNOPYPEQARSSKSICHAEUYUZ"
                  >
                    PELAA NYT
                  </CasinoButton>
                </div>
              </ContentButton>
              <div
                className={`expand-button ${cardExpanded ? "bg-grey" : ""}`}
                onClick={() => setCardExpanded(!cardExpanded)}
              >
                <button className="expand-button-btn">{cardExpanded ? "Lisätietoa" : "Lue Lisää"}</button>
                <ArrowIcon className={cardExpanded ? "transform-icon show-icon" : "not-transform show-icon"} />
              </div>
            </div>
          </div>
          {inView && (
            <div className="info-card-bottom">
              <div className="bottom-info-row">
                <div className="bottom-info-col">Pelien määrä: {pelienMr}</div>
                <div className="bottom-info-col">Lisenssi: {lisenssi}</div>
              </div>
              <div className="card-features">
                {allFeatures.map((feature) => {
                  return (
                    <div key={feature} className="feature-casino-item">
                      <div
                        className={`feature-casino-item-icon ${
                          features.includes(feature) ? "item-icon-green" : "item-icon-gray"
                        }`}
                      >
                        {features.includes(feature) ? <DoneIcon /> : <CrossIcon />}
                      </div>
                      <span>{feature}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </CasinoTop>
      </div>
    </Casino>
  );
};

const CasinosList = ({ title, casinosCadsData }) => {
  return (
    <section className="casino-section">
      <div className="kasinolista">
        {title && <h2 className="has-text-align-center">{title}</h2>}
        <div className="casinoTable testi">
          {casinosCadsData.length &&
            casinosCadsData.map((casinoItem) => {
              return (
                <CasinoCard
                  key={casinoItem?.casinoName}
                  casinoName={casinoItem?.name}
                  description={casinoItem?.description}
                  logo={casinoItem?.logo}
                  link={casinoItem?.link}
                  rating={casinoItem?.rating}
                  features={casinoItem?.features}
                  bonukset={casinoItem?.bonukset}
                  ilmaiskierrokset={casinoItem?.ilmaiskierrokset}
                  lisenssi={casinoItem?.lisenssi}
                  pelienMr={casinoItem?.pelienMr}
                />
              );
            })}
        </div>
      </div>
    </section>
  );
};

const Casino = styled.div`
  background-color: #fff;
  border-radius: 3px;
  display: block;
  flex-wrap: wrap;
  margin-bottom: 10px;
  max-width: 1000px;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

const CasinoTop = styled.div`
  align-items: center;
  border: 1px solid #dcd7ca;
  border-radius: 8px;
  flex-direction: column;
  height: 100%;
  line-height: 1.2;
  max-height: 120px;
  overflow: hidden;
  padding: 0;
  transition: max-height 0.1s ease-in-out;

  @media (max-width: 500px) {
    max-height: 280px;
  }
`;
const CasinoButton = styled.a`
  background: #009864;
  border: 1px solid #009864;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Roboto Condensed;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  padding: 8px 24px;
  text-decoration: none;
  text-transform: uppercase;

  @media (max-width: 500px) {
    padding: 6px 22px;
  }
`;

const Offer = styled.div`
  font-family: Roboto Condensed;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
  margin: 0 0 0.2em;
`;

const BodyText = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.71;
`;

const Logo = styled.div`
  display: flex;
  flex-direction: column;
  height: 80px;
  justify-content: space-between;
  margin-right: 48px;
  text-align: center;
  width: 100px;
  a {
    margin-bottom: 12px;
  }
  .title {
    font-size: 14px !important;
    font-weight: 500;
    margin: 0;
    padding: 0;
  }
  img {
    display: block;
    max-width: 100px;
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }

  @media (max-width: 800px) {
    max-width: 100px;
    align-items: center;
    background-color: transparent;
    border-right: none;
    float: left;
  }
  @media (max-width: 500px) {
    margin: 0;
    width: 50%;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 80%;

  @media (max-width: 500px) {
    flex-wrap: wrap;
    padding: 24px 24px 12px;
    width: 100%;
  }
`;

const BoxFirst = styled.div`
  display: inline-block;
  height: 80px;
  width: 30%;

  @media (max-width: 500px) {
    width: 50%;
    padding-top: 10px;
  }
`;

const OfferItem = styled.div`
  float: left;
  font-size: 13px;
  width: 50%;
  margin-bottom: 9.5px;

  span {
    font-family: Roboto Condensed;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.71;
  }

  @media (max-width: 800px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  @media (max-width: 500px) {
    margin-bottom: 5px;
  }
`;

const CasinoRate = styled.div`
  display: flex;
  float: left;
  width: 100%;
`;

const BoxSecond = styled.div`
  width: 50%;
  display: inline-block;
  height: 80px;

  @media (max-width: 800px) {
    height: auto;
    min-height: 81px;
    width: 100%;
  }

  @media (max-width: 500px) {
    padding-top: 10px;
    margin-top: 5px;
  }
`;

const ContentButton = styled.div`
  padding: 12px 0 12px 24px;
  @media (max-width: 800px) {
    border-top: 1px solid #dcd7ca;
    display: inherit;
    margin: auto;
    padding-left: 0;
    width: 50%;
    flex-direction: row;
    justify-content: center;
  }
`;

export default CasinosList;
